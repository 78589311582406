// main imports
import * as React from "react";
import { graphql } from "gatsby";

// plugin imports
import { Trans, useI18next, useTranslation } from "gatsby-plugin-react-i18next";

// style imports
import "../../index.scss";
import "../menus.scss";

// svg imports

// components imports
import MenuLayout from "../../../components/Layout/MenuLayout.js";
import Seo from "../../../components/Seo/Seo.js";
// import MenuTitle from "../../../components/Menus/MenuTitle";
// import Price from "../../../components/Menus/Price";

// page
export default function IndexPage() {
	const { language } = useI18next();
	const { t } = useTranslation("menuWines");

	return (
		<MenuLayout>
			<Seo lang={language} title={t("wines menu")} />
			<div className="bodyContainer menu wines">
				<section>
					<div>
						<h1>
							<Trans ns="menuWines">wines menu</Trans>.
						</h1>

						<table>
							<thead>
								<tr>
									<th>
										<span>
											#<Trans ns="menuWines">prosecco/sparkling wine</Trans>
										</span>
										.
									</th>
									<th>
										<Trans ns="menuWines">glass</Trans>
									</th>
									<th>
										<Trans ns="menuWines">bottle</Trans>
									</th>
									<th aria-label="4col"></th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>Prosecco Rivalta.</td>
									<td>&nbsp;8.</td>
									<td>45.</td>
								</tr>
								<tr>
									<td>Chandon Garden.</td>
									<td aria-label="Empty column"></td>
									<td>55.</td>
								</tr>
							</tbody>
						</table>

						<table>
							<thead>
								<tr>
									<th>
										<span>
											#<Trans ns="menuWines">champagne brut</Trans>
										</span>
										.
									</th>
									<th aria-label="2col"></th>
									<th>
										<Trans ns="menuWines">bottle</Trans>
									</th>
									<th aria-label="4col"></th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>Moët & Chandon.</td>
									<td aria-label="Empty column"></td>
									<td>95.</td>
								</tr>
								<tr>
									<td>EPC Brut.</td>
									<td aria-label="Empty column"></td>
									<td>95.</td>
								</tr>
								<tr>
									<td>Veuve Cliquot.</td>
									<td aria-label="Empty column"></td>
									<td>105.</td>
								</tr>
								<tr>
									<td>Perrier Joet Grand Brut.</td>
									<td aria-label="Empty column"></td>
									<td>105.</td>
								</tr>
								<tr>
									<td>EPC Premier Cru.</td>
									<td aria-label="Empty column"></td>
									<td>120.</td>
								</tr>
								<tr>
									<td>Moët Ice.</td>
									<td aria-label="Empty column"></td>
									<td>135.</td>
								</tr>
							</tbody>
						</table>

						<table>
							<thead>
								<tr>
									<th>
										<span>
											#<Trans ns="menuWines">champagne rosé</Trans>
										</span>
										.
									</th>
									<th aria-label="2col"></th>
									<th>
										<Trans ns="menuWines">bottle</Trans>
									</th>
									<th aria-label="4col"></th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>EPC Rosé Blancs.</td>
									<td aria-label="Empty column"></td>
									<td>115.</td>
								</tr>
								<tr>
									<td>Moët Rosé.</td>
									<td aria-label="Empty column"></td>
									<td>125.</td>
								</tr>
								<tr>
									<td>Laurent-Perrier Rosé.</td>
									<td aria-label="Empty column"></td>
									<td>155.</td>
								</tr>
								<tr>
									<td>Bollinger Rosé.</td>
									<td aria-label="Empty column"></td>
									<td>155.</td>
								</tr>
								<tr>
									<td>Ruinart Rose.</td>
									<td aria-label="Empty column"></td>
									<td>175.</td>
								</tr>
							</tbody>
						</table>

						<table>
							<thead>
								<tr>
									<th>
										<span>
											#<Trans ns="menuWines">champagne blanc de blancs</Trans>
										</span>
										.
									</th>
									<th aria-label="2col"></th>
									<th>
										<Trans ns="menuWines">bottle</Trans>
									</th>
									<th aria-label="4col"></th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>EPC Blanc de Blancs.</td>
									<td aria-label="Empty column"></td>
									<td>105.</td>
								</tr>
								<tr>
									<td>EPC Blanc de Noir.</td>
									<td aria-label="Empty column"></td>
									<td>120.</td>
								</tr>
								<tr>
									<td>Ruinart Blanc de Blancs.</td>
									<td aria-label="Empty column"></td>
									<td>175.</td>
								</tr>
							</tbody>
						</table>

						<h2>
							<Trans ns="menuWines">rose wines</Trans>.
						</h2>

						<table className="countryWines">
							<thead>
								<tr>
									<th>
										<span>
											#<Trans ns="menuWines">france</Trans>
										</span>
										.
									</th>
									<th>
										<Trans ns="menuWines">glass</Trans>
									</th>
									<th>
										<Trans ns="menuWines">bottle</Trans>
									</th>
									<th aria-label="4col"></th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>Symphonie.</td>
									<td>5.5</td>
									<td>38.</td>
								</tr>
								<tr>
									<td>Symphonie. Magnum 1.5l.</td>
									<td aria-label="Empty column"></td>
									<td>70.</td>
								</tr>
								<tr>
									<td>Château Saint-Maur Cru Classe.</td>
									<td aria-label="Empty column"></td>
									<td>45.</td>
								</tr>
								<tr>
									<td>Whispering Angel.</td>
									<td aria-label="Empty column"></td>
									<td>45.</td>
								</tr>
								<tr>
									<td>Whispering Angel. Magnum 1.5l.</td>
									<td aria-label="Empty column"></td>
									<td>85.</td>
								</tr>
								<tr>
									<td>Miraval Studio.</td>
									<td aria-label="Empty column"></td>
									<td>38.</td>
								</tr>
								<tr>
									<td>Miraval.</td>
									<td aria-label="Empty column"></td>
									<td>48.</td>
								</tr>
								<tr>
									<td>Miraval. Magnum 1.5l.</td>
									<td aria-label="Empty column"></td>
									<td>85.</td>
								</tr>
							</tbody>
						</table>
						{/* <table className="countryWines">
							<thead>
								<tr>
									<th>
										<span>
											#<Trans ns="menuWines">italy</Trans>
										</span>
										.
									</th>
									<th aria-label="2col"></th>
									<th>
										<Trans ns="menuWines">bottle</Trans>
									</th>
									<th aria-label="4col"></th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>Costa di Rose.</td>
									<td aria-label="Empty column"></td>
									<td>38.</td>
								</tr>
								<tr>
									<td>Costa di Rose. Magnum 1.5l.</td>
									<td aria-label="Empty column"></td>
									<td>85.</td>
								</tr>
							</tbody>
						</table> */}

						<h2>
							<Trans ns="menuWines">white wines</Trans>.
						</h2>

						<table className="countryWines">
							<thead>
								<tr>
									<th>
										<span>
											#<Trans ns="menuWines">spain</Trans>
										</span>
										.
									</th>
									<th>
										<Trans ns="menuWines">glass</Trans>
									</th>
									<th>
										<Trans ns="menuWines">bottle</Trans>
									</th>
									<th aria-label="4col"></th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>Jose Pariente Verdejo.</td>
									<td>5.5</td>
									<td>28.</td>
								</tr>
								<tr>
									<td>Jose Pariente Sauvignon.</td>
									<td>5.5</td>
									<td>28.</td>
								</tr>
								<tr>
									<td>Belondrade Quinta Apolonia. 2022.</td>
									<td aria-label="Empty column"></td>
									<td>38.</td>
								</tr>
								<tr>
									<td>Belondrade y Lurton. 2021.</td>
									<td aria-label="Empty column"></td>
									<td>65.</td>
								</tr>
							</tbody>
						</table>

						<table className="countryWines">
							<thead>
								<tr>
									<th>
										<span>
											#<Trans ns="menuWines">italy</Trans>
										</span>
										.
									</th>
									<th aria-label="2col"></th>
									<th>
										<Trans ns="menuWines">bottle</Trans>
									</th>
									<th aria-label="4col"></th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>Jerman Pinot Grigio.</td>
									<td aria-label="Empty column"></td>
									<td>45.</td>
								</tr>
							</tbody>
						</table>

						<table className="countryWines">
							<thead>
								<tr>
									<th>
										<span>
											#<Trans ns="menuWines">new zealand</Trans>
										</span>
										.
									</th>
									<th aria-label="2col"></th>
									<th>
										<Trans ns="menuWines">bottle</Trans>
									</th>
									<th aria-label="4col"></th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>Cloudy Bay Sauvignon Blanc.</td>
									<td aria-label="Empty column"></td>
									<td>70.</td>
								</tr>
								<tr>
									<td>Cloudy Bay Chardonnay.</td>
									<td aria-label="Empty column"></td>
									<td>75.</td>
								</tr>
							</tbody>
						</table>

						<table className="countryWines">
							<thead>
								<tr>
									<th>
										<span>
											#<Trans ns="menuWines">france</Trans>
										</span>
										.
									</th>
									<th aria-label="2col"></th>
									<th>
										<Trans ns="menuWines">bottle</Trans>
									</th>
									<th aria-label="4col"></th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>Ljadot. Chardonnay. 2020.</td>
									<td aria-label="Empty column"></td>
									<td>55.</td>
								</tr>
								<tr>
									<td>Louis Latour Pouilly Fuisse.</td>
									<td aria-label="Empty column"></td>
									<td>85.</td>
								</tr>
								<tr>
									<td>Louis Latour Chablis.</td>
									<td aria-label="Empty column"></td>
									<td>95.</td>
								</tr>
							</tbody>
						</table>
{/* 
						<table className="countryWines">
							<thead>
								<tr>
									<th>
										<span>
											#<Trans ns="menuWines">italy</Trans>
										</span>
										.
									</th>
									<th aria-label="2col"></th>
									<th>
										<Trans ns="menuWines">bottle</Trans>
									</th>
									<th aria-label="4col"></th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>Jermann Pinot Grigio.</td>
									<td aria-label="Empty column"></td>
									<td>45.</td>
								</tr>
							</tbody>
						</table> */}

						{/* <table className="countryWines">
							<thead>
								<tr>
									<th>
										<span>
											#<Trans ns="menuWines">austria</Trans>
										</span>
										.
									</th>
									<th aria-label="2col"></th>
									<th>
										<Trans ns="menuWines">bottle</Trans>
									</th>
									<th aria-label="4col"></th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>Gobelsburg. Grüner Veltliner.</td>
									<td>5.5</td>
									<td>28.</td>
								</tr>
							</tbody>
						</table> */}

						<h2>
							<Trans ns="menuWines">red wines</Trans>.
						</h2>

						<table className="countryWines">
							<thead>
								<tr>
									<th>
										<span>
											#<Trans ns="menuWines">spain</Trans>
										</span>
										.
									</th>
									<th>
										<Trans ns="menuWines">glass</Trans>
									</th>
									<th>
										<Trans ns="menuWines">bottle</Trans>
									</th>
									<th aria-label="4col"></th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>La Pesquera. Ribera del Duero. Crianza.</td>
									<td aria-label="Empty column"></td>
									<td>45.</td>
								</tr>
								<tr>
									<td>
										Pago de los Capellanes. Ribera del Duero. Crianza 2020.
									</td>
									<td aria-label="Empty column"></td>
									<td>50.</td>
								</tr>
								<tr>
									<td>
										Pago de los Capellanes. Ribera del Duero. Reserva 2019.
									</td>
									<td aria-label="Empty column"></td>
									<td>68.</td>
								</tr>
								<tr>
									<td>Macan Clásico. Rioja. Crianza.</td>
									<td aria-label="Empty column"></td>
									<td>95.</td>
								</tr>
								<tr>
									<td>Roda I. Rioja. Reserva 2016.</td>
									<td aria-label="Empty column"></td>
									<td>95.</td>
								</tr>
								<tr>
									<td>Numanthia D.O. Toro. 2017.</td>
									<td aria-label="Empty column"></td>
									<td>95.</td>
								</tr>
								<tr>
									<td>Pintia. Toro.</td>
									<td aria-label="Empty column"></td>
									<td>98.</td>
								</tr>
							</tbody>
						</table>

						<table className="countryWines">
							<thead>
								<tr>
									<th>
										<span>
											#<Trans ns="menuWines">italy</Trans>
										</span>
										.
									</th>
									<th aria-label="2col"></th>
									<th>
										<Trans ns="menuWines">bottle</Trans>
									</th>
									<th aria-label="4col"></th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>Amarone Tommasi. Della Valpolicella Classico. 2018.</td>
									<td aria-label="Empty column"></td>
									<td>95.</td>
								</tr>
								<tr>
									<td>Tignanello.</td>
									<td aria-label="Empty column"></td>
									<td>195.</td>
								</tr>
							</tbody>
						</table>

						{/* <table className="countryWines">
							<thead>
								<tr>
									<th>
										<span>
											#<Trans ns="menuWines">austria</Trans>
										</span>
										.
									</th>
									<th aria-label="2col"></th>
									<th>
										<Trans ns="menuWines">bottle</Trans>
									</th>
									<th aria-label="4col"></th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>Gobelsburg. Zweigelt. Austria.</td>
									<td>5.5</td>
									<td>33.</td>
								</tr>
							</tbody>
						</table> */}
					</div>
				</section>
			</div>
		</MenuLayout>
	);
}

// graphql query
export const query = graphql`
	query ($language: String!) {
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
	}
`;
